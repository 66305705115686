//
// Nav
// --------------------------------------------------


// NAV PILLS
// -----------
.nav-pills {
  > li {
    &:first-child {
      > a {
        border-left: none;
        border-radius: @nav-border-radius 0 0 @nav-border-radius;
      }
    }
    &:last-child {
      > a {
        border-radius: 0 @nav-border-radius @nav-border-radius 0;
      }
    }
    &.active,
    &.open {
      > a,
      > a:hover {
        background-color: @btn-primary-active-bg;
      }
    }

    // Open dropdowns
    &.open > a {
      &,
      &:hover,
      &:focus {
        background-color: @btn-primary-active-bg;
        border-color: @btn-primary-active-bg;
      }
    }

    @media (max-width: @screen-xs-max) {
      float: none;
      display: block;

      &:first-child > a { border-radius: @nav-border-radius @nav-border-radius 0 0; }
      &:last-child > a  { border-radius: 0 0 @nav-border-radius @nav-border-radius; }
    }

    > a {
      background-color: @brand-secondary;
      border-left: 2px solid @btn-primary-active-bg;
      color: @inverse;
      font-size: floor((@component-font-size-base * 1.067)); // ~16px
      font-weight: 700;
      line-height: 1;
      margin: 0;
      outline: none;
      padding: 12px 21px 13px;
      border-radius: 0;

      @media (max-width: @screen-xs-max) {
        border-left: none;
        display: block;
      }

      &:hover,
      &:focus {
        background-color: @btn-primary-hover-bg;
      }
      &:active {
        background-color: @btn-primary-active-bg;
      }

      > .badge {
        margin: -4px 0 -4px 3px;
        position: relative;
        top: -1px;
        background-color: @inverse;
      }
    }
  }
  > li + li {
    margin-left: 0;
  }
}

// Stacked pills
.nav-stacked {
  > li,
  > li:first-child,
  > li:last-child {
    > a {
      border-left: none;
      border-radius: @nav-border-radius;

      > .badge {
        top: 2px;
      }
    }
  }
}

// NAV TABS
// -----------
.nav-tabs {
  border-bottom: none;

  // List item
  > li {
    display: block;
    float: none;
    margin-bottom: -@nav-tabs-border-width;
    position: relative;

    // Active link
    &.active {
      z-index: 2;

      > a,
      > a:hover,
      > a:focus {
        background-color: @nav-tabs-hover-color;
        border: 2px solid @nav-tabs-hover-color;
        color: @inverse;
        &:before { display: none; }
      }
      .dropdown-toggle,
      .dropdown-toggle:hover,
      .dropdown-toggle:focus {
        .caret {
          border-bottom-color: @nav-tabs-active-color;
          border-top-color: @nav-tabs-active-color;
        }
      }
    }

    // Dropdown
    &.dropdown {
      &.active {
        > a,
        > a.dropdown-toggle,
        > a:hover {
          color: @inverse;

          .caret {
            border-bottom-color: @inverse;
            border-top-color: @inverse;
          }
        }
        &.open {
          > a,
          > a.dropdown-toggle,
          > a:hover {
            color: @nav-tabs-active-color;
            border-radius: @nav-item-border-radius @nav-item-border-radius @nav-item-border-radius 0;

            .caret {
              border-bottom-color: @nav-tabs-active-color;
              border-top-color: @nav-tabs-active-color;
            }
          }
        }
      }
    }

    // Dropdown is open
    &.open,
    &.open.active {
      a.dropdown-toggle,
      a.dropdown-toggle:hover {
        background-color: @dropdown-bg;
        border-color: @nav-tabs-border-color;
        color: @nav-tabs-active-color;

        @media (min-width: @screen-sm-min) {
          border-bottom-color: transparent;
        }

        .caret {
          border-bottom-color: @nav-tabs-active-color;
          border-top-color: @nav-tabs-active-color;
        }
      }

      .dropdown-menu {
        margin-top: -2px;
      }
    }
    &.open.active {
      z-index: (@nav-open-zindex + 2);
    }

    // General link styling
    > a {
      border: 2px solid @nav-tabs-border-color;
      border-width: @nav-tabs-border-width;
      font-size: ceil((@component-font-size-base * 0.933)); // ~14px
      font-weight: 700;
      margin-bottom: 10px;
      padding: 10px 15px;
      color: @nav-tabs-color;
      border-radius: @nav-item-border-radius;

      transition: none;

      &:hover,
      &:focus {
        background-color: transparent;
        border: 2px solid @nav-tabs-border-color;
        outline: none;
        color: @nav-tabs-hover-color;
      }
    }

    // Dropdown
    .dropdown-toggle {
      position: relative;
      z-index: (@nav-open-zindex + 1);

      &:hover,
      &:focus {
        .caret {
          border-bottom-color: @nav-tabs-hover-color;
          border-top-color: @nav-tabs-hover-color;
        }
      }
    }
    .dropdown-menu {
      border: @nav-tabs-border-width solid @nav-tabs-border-color;
      border-radius: 0 @nav-border-radius @nav-border-radius @nav-border-radius;

      li {
        &:first-child > a {
          border-radius: 0 @nav-item-border-radius 0 0;
        }
        &:last-child > a {
          border-radius: 0 0 @nav-item-border-radius @nav-item-border-radius;
        }
        a {
          border-radius: 0;
        }
      }
    }
    .open .dropdown-toggle {
      background-color: @dropdown-bg;
    }
  }

  @media (min-width: @screen-sm-min) {
    margin-bottom: 20px;
    border-bottom-width: @nav-tabs-border-width;
    border-bottom-color: @nav-tabs-border-color;

    // With tabs content appended
    &.nav-append-content {
      border-bottom: none;
      margin-bottom: 0;

      > li {
        &.active:first-child:before {
          display: block;
          background-color: @inverse;
          border-left: @nav-tabs-border-width solid @nav-tabs-border-color;
          content: "";
          height: @nav-border-radius;
          left: 0;
          position: absolute;
          bottom: (@nav-tabs-border-width - @nav-border-radius);
          width: @nav-border-radius;
          z-index: 2;
        }

        // Dropdown
        &.dropdown {
          &.active {
            > a,
            > a.dropdown-toggle,
            > a:hover {
              color: @nav-tabs-active-color;

              .caret {
                border-bottom-color: @nav-tabs-active-color;
                border-top-color: @nav-tabs-active-color;
              }
            }
          }
        }

      }
    }

    > li {
      float: left;
      &.active {
        > a,
        > a:hover,
        > a:focus {
          background-color: inherit;
          border-color: @nav-tabs-border-color;
          border-bottom-color: @inverse;
          border-width: @nav-tabs-border-width;
          color: @nav-tabs-active-color;
        }
        &:before { display: none; }
      }

      > a,
      > a:hover,
      > a:focus {
        border: none;
        margin-right: 0;
        margin-bottom: 0;
        padding: 7px 21px 8px;
        border-radius: @nav-border-radius @nav-border-radius 0 0;
        border-color: transparent;
        border-bottom-color: transparent;
      }
    }
 }
}

// Tab content
.tab-content {
  border: 2px solid #ddd;
  border-radius: @nav-border-radius;
  margin-bottom: 20px;
  padding: 20px 20px 5px;
  position: relative;
  z-index: 1;
}

// NAV LIST
// -----------
.nav-list {
  padding-left: @nav-list-gap;
  padding-right: @nav-list-gap;

  .nav-header {
    margin-left: -@nav-list-gap;
    margin-right: -@nav-list-gap;
    padding: 0 @nav-list-gap;
    font-weight: 700;
    line-height: 1.538; // ~20px
  }

  > li {
    margin-bottom: 3px;

    &.active {
      > a,
      > a:hover,
      > a:focus {
        background-color: @nav-list-bg;
        color: @nav-list-color;
      }
    }

    &.disabled {
      > a,
      > a:hover,
      > a:focus {
        opacity:0.6;
        cursor: @cursor-disabled;
      }
    }

    &.divider {
      height: 2px;
      border-bottom: none;
      margin: 13px -@nav-list-gap 17px 0;
      background-color: @nav-list-bg;
    }

    > a {
      border-radius: @nav-item-border-radius;
      font-size: @component-font-size-base;
      line-height: 1.333; // ~20px
      font-weight: 700;
      margin-left: -@nav-list-gap;
      margin-right: -@nav-list-gap;
      padding: 6px @nav-list-gap 8px;
      text-shadow: none;
    }
  }
  .badge.pull-right {
    margin: 1px -4px -1px 0;
  }
}

.nav-header {
  color: @nav-list-header-color;
  font-size: floor((@component-font-size-base * 0.867)); // ~13px
  text-transform: uppercase;
}

// Nav list with a background color
.nav-list-vivid {
  background-color: @nav-list-bg;
  border-radius: @nav-item-border-radius;
  padding-bottom: 2px;

  > li {
    margin-bottom: 2px;

    &.divider {
      background-color: transparent;
      margin: 3px 0 0;
    }
    &.active {
      > a,
      > a:hover,
      > a:focus {
        background-color: @nav-list-active-bg;
        color: @inverse;
      }
    }
    > a {
      border-radius: 0;
      color: @nav-list-color;
      font-size: ceil((@component-font-size-base * 0.933)); // ~14px

      &:hover,
      &:focus {
        color: @nav-list-hover-color;
      }
    }
  }
  .nav-header {
    &:first-child {
      padding-top: 10px;
    }
  }
}

.nav-list-bricks {

  > li {
    margin-bottom: 7px;

    &.active {
      > a,
      > a:hover,
      > a:focus {
        background-color: @nav-list-active-bg;
        color: @nav-list-color-inverse;
      }
    }
    &.disabled {
      > a,
      > a:hover,
      > a:focus {
        background-color: @nav-list-bg;
      }
    }
    > a {
      padding: 8px 10px 8px 14px;
      border-radius: @nav-item-border-radius;
      background-color: @nav-list-bg;
      color: @nav-list-color;
      font-size: ceil((@component-font-size-base * 0.933)); // ~14px
      line-height: 1.333; // ~20px
      font-weight: 700;

      &:hover,
      &:focus {
        color: @nav-list-hover-color;
      }
      [class^="fui-"], [class*=" fui-"] {
        color:@nav-list-color-inverse;
      }
    }
  }
}
