//
// Data Tables
// --------------------------------------------------


// Number of elements to display on a page.

.dataTables_length {
  label {
    font-weight: normal;
    float: left;
    text-align: left;
  }
  select {
    width: @datatables-select-width;
    margin-right: 5px;
  }
}


// Filter input

.dataTables_filter {
  label {
    font-weight: normal;
    float: right;
  }
  input {
    width: @datatables-filter-width;
    margin-left: 5px;
  }
}
.dataTables_info {
  padding-top: 8px;
}


// Pagination

.dataTables_paginate {
  float: right;
  margin: 0;
  &:extend(.pagination all);

  ul li {
    margin-right: 0;
  }
}


// Base styles

.dataTable {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;

  td,
  th {
    position: relative;
    box-sizing: content-box;
  }

  thead {
    .sorting,
    .sorting_asc,
    .sorting_desc,
    .sorting_asc_disabled,
    .sorting_desc_disabled {
      cursor: pointer;

      &:after,
      &:before {
        content: " ";
        display: block;
        position: absolute;
        right: 5px;
        width: 0;
        height: 0;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
      }
    }
    .sorting,
    .sorting_asc,
    .sorting_asc_disabled {
      &:after {
        border-bottom-width: 6px;
        top: 10px;
      }
    }
    .sorting,
    .sorting_desc,
    .sorting_desc_disabled {
      &:before {
        border-top-width: 6px;
        bottom: 10px;
      }
    }
    .sorting_asc_disabled:after,
    .sorting_desc_disabled:before {
      color: @datatables-disabled-color;
    }
  }

  th:active {
    outline: none;
  }
}


// Scrolling

.dataTables_scrollHead table {
  margin-bottom: 0 !important;
  .border-bottom-radius(0);

  thead tr:last-child {
    th:first-child,
    td:first-child {
      .border-bottom-radius(~"0 !important");
    }
  }
}

.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  tbody tr:last-child {
    th,
    td {
      border-top: none;
    }
  }
}

.dataTables_scrollFoot table {
  margin-top: 0 !important;
  border-top: none;
}

/* Frustratingly the border-collapse:collapse used by Bootstrap makes the column
   width calculations when using scrolling impossible to align columns. We have
   to use separate
 */

// Styles for bordered table

.table-bordered {
  tr th {
    border-bottom-width: 0;
  }

  thead th,
  thead td {
    border-left-width: 0;
    border-top-width: 0;
  }
  tbody th,
  tbody td {
    border-left-width: 0;
    border-bottom-width: 0;
  }
  th:last-child,
  td:last-child {
    border-right-width: 0;
  }

  .dataTables_scrollHead & {
    border-bottom-width: 0;
  }
}


// TableTools styles

.table tbody tr.active {
  td,
  th {
    background-color: @datatables-tr-active;
    color: @datatables-tr-active-color;
  }
  &:hover td,
  &:hover th {
    background-color: @datatables-tr-active-hover !important;
  }
  a {
    color: @datatables-tr-active-color;
  }
}

.table-striped tbody tr.active:nth-child(odd) {
  td,
  th {
    background-color: @datatables-tr-active;
  }
}


// Styles for DataTables TableTools plugin (DTTT)

.DTTT_selectable tbody tr {
  cursor: pointer;
}

.DTTT .btn {
  font-size: 12px;

  &:hover {
    text-decoration: none !important;
  }
}

.DTTT_dropdown.dropdown-menu {
  z-index: 2003;

  li {
    position: relative;
  }
}

.DTTT_collection_background {
  z-index: 2002;
}


// DTTT information display

.DTTT_print_info {
  &.modal {
    height: 150px;
    margin-top: -75px;
    text-align: center;
  }
  h6 {
    font-weight: normal;
    font-size: 28px;
    line-height: 28px;
    margin: 1em;
  }
  p {
    font-size: 14px;
    line-height: 20px;
  }
}

.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 40px;
  margin-left: -50%;
  margin-top: -25px;
  padding-top: 20px;
  text-align: center;
  font-size: 1.2em;
  background-color: white;
  background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
}


// FixedHeader styles

.FixedHeader_Cloned table {
  margin: 0 !important
}

@media (max-width: @screen-xs-max) {
  .dataTables_paginate {
    ul.pagination {
      padding-left: 0;
      padding-right: 0;

      .paginate_button {
        &.previous, &.next {
          display: none;
        }
      }
    } 
  }
}

@media (max-width: @screen-xs-min) {
  .dataTables_paginate {
    width: 100%; 
  }
}
