//
// File input
// --------------------------------------------------

.btn-file {
  overflow: hidden;
  position: relative;
  //vertical-align: middle;

  > input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: 0;
    font-size: 23px;
    direction: ltr;
    cursor: pointer;
    width: 100%;
    height: 100%;
    outline: 0;
    .opacity(0);
  }
}

.fileinput {
  margin-bottom: 9px;
  display: block;
  overflow: hidden;

  .form-control {
    display: inline-block;
    margin-bottom: 0;
    cursor: text;
  }
  .input-group-addon {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .thumbnail {
    overflow: hidden;
    display: inline-block;
    margin-bottom: 5px;
    text-align: center;
    > img {
      max-height: 100%;
    }
  }
  .uneditable-input [class^="fui-"] {
    margin-right: .3em;
  }
}
.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
  display: none;
}
.fileinput-inline .fileinput-controls {
  display: inline;
}
.fileinput-filename {
  vertical-align: middle;
  overflow: hidden;
  font-size: @component-font-size-base;
  line-height: (@input-line-height-base * 1.14);
}
.form-control .fileinput-filename {
  vertical-align: bottom;
}
.fileinput.input-group {
    display: table;

    > * {
        position: relative;
        z-index: 2;
    }
    > .btn-file {
        z-index: 1;
    }
}

// Not 100% correct, but helps in typical use case
.fileinput-new.input-group .btn-file,
.fileinput-new .input-group .btn-file {
  border-radius: 0 @border-radius-base @border-radius-base 0;

  &.btn-xs,
  &.btn-sm {
    border-radius: 0 @border-radius-small @border-radius-small 0;
  }
  &.btn-lg {
    border-radius: 0 @border-radius-large @border-radius-large 0;
  }
}

.form-group.has-warning .fileinput {
  .fileinput-preview {
    color: @state-warning-text;
  }
  .thumbnail {
    border-color: @state-warning-border;
  }
}
.form-group.has-error .fileinput {
  .fileinput-preview {
    color: @state-danger-text;
  }
  .thumbnail {
    border-color: @state-danger-border;
  }
}
.form-group.has-success .fileinput {
  .fileinput-preview {
    color: @state-success-text;
  }
  .thumbnail {
    border-color: @state-success-border;
  }
}


// Input group fixes

.input-group-addon:not(:first-child) {
  border-left: 0;
}
