//
// Input groups
// --------------------------------------------------

// Base styles
// -------------------------
.input-group {
   .form-control {
    position: static;
  }
}

// Sizing options
//
// Remix the default form control sizing classes into new ones for easier
// manipulation.

.input-group-hg > .form-control,
.input-group-hg > .input-group-addon,
.input-group-hg > .input-group-btn > .btn { .input-hg(); }
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn { .input-lg(); }
.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn { .input-sm(); }

// Text input groups
// -------------------------
.input-group-addon {
  padding: 10px 12px;
  font-size: @component-font-size-base;
  color: @inverse;
  text-align: center;
  background-color: @gray-light;
  border: 2px solid @gray-light;
  border-radius: @border-radius-large;
  transition: border .25s linear, color .25s linear, background-color .25s linear;
  
  .input-group-hg &,
  .input-group-lg &,
  .input-group-sm &, {
    line-height: 1;
  }

  .checkbox, .radio {
    margin: 0;
    padding-left: 19px;
  }
}

// Reset rounded corners
.form-controls-corners-reset();



// Focus State
// -------------------------
.form-group.focus,
.input-group.focus {
  .input-group-addon {
    background-color: @brand-secondary;
    border-color: @brand-secondary;
  }
  .input-group-btn {
    > .btn-default + .btn-default {
      border-left-color: mix(@brand-secondary, black, 85%);
    }
    .btn {
      border-color: @brand-secondary;
      background-color: @inverse;
      color: @brand-secondary;
    }
    .btn-default {
      .button-variant(@btn-default-color, @brand-secondary, @btn-primary-hover-bg, @btn-primary-active-bg);
    }
  }
}

// Button input groups
// -------------------------
.input-group-btn {    
  .btn {
    background-color: @inverse;
    border: 2px solid @gray-light;    
    color: @gray-light;
    line-height: 18px;
    height: 42px;
  } 
  .btn-default {
    .button-variant(@btn-default-color, @gray-light, @btn-hover-bg, @btn-active-bg);
  }
  .input-group-hg & .btn {
    line-height: 31px;
  }
  .input-group-lg & .btn {
    line-height: 21px;
  }
  .input-group-sm & .btn {
    line-height: 19px;
  }
  &:first-child > .btn {
    border-right-width: 0;
    margin-right: -3px;
  }
  &:last-child > .btn {
    border-left-width: 0;
    margin-left: -3px;
  }
  & > .btn-default + .btn-default {
    border-left: 2px solid @gray-light;
  }
  & > .btn:first-child + .btn {
    .caret {
      margin-left: 0;
    }
  }
}


// Rounded input groups
// -------------------------
.input-group-rounded {
  .input-group-btn + .form-control,
  .input-group-btn:last-child .btn {
    .border-right-radius(20px);
    
    .input-group-hg& {
      .border-right-radius(27px);
    }
    .input-group-lg& {
      .border-right-radius(25px);
    }
  } 
  .form-control:first-child,
  .input-group-btn:first-child .btn {
    .border-left-radius(20px);
    
    .input-group-hg& {
      .border-left-radius(27px);
    }
    .input-group-lg& {
      .border-left-radius(25px);
    }
  } 
  // Remove left padding for .form-control after button
  .input-group-btn + .form-control {
    padding-left: 0;
  }
}