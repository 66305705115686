//
// Alerts
// --------------------------------------------------

// Base styles
.alert {
  color: @brand-primary;
  padding: 18px 45px 14px 30px;
  border: 2px solid transparent;
  font-size: floor((@component-font-size-base * 1.067)); // ~16px
  line-height: 1.625; // ~26px
  border-radius: @border-radius-large;

  .alert-link {
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
    &.btn {
      color: @btn-default-color;
      text-decoration: none;

      &:active,
      &.active {
        color: fade(@btn-default-color, 75%);
      }
    }
  }

  h4 {
    font-size: ceil((@component-font-size-base * 1.6)); // ~24px
    line-height: 1.2; // ~28.8px
    margin-bottom: 4px;
  }

  p {
    font-size: inherit;
    line-height: inherit;
  }

  .close {
    position: relative;
    top: 3px;
    right: -31px;
    line-height: 18px;
    font-size: 18px;
    color: @brand-primary;
  }

  .btn {
    margin-bottom: 15px;
    margin-right: 15px;
    margin-top: 15px;

    @media (max-width: @screen-xs-max) {
      display: block;
      min-width: auto;
      margin-bottom: 10px;
      margin-right: -15px;
    }
  }

}

// Variations
// -------------------------

.alert-success { .alert-variant(@alert-success-bg, @alert-success-border, @alert-success-link-color); }
.alert-info    { .alert-variant(@alert-info-bg,    @alert-info-border,    @alert-info-link-color); }
.alert-warning { .alert-variant(@alert-warning-bg, @alert-warning-border, @alert-warning-link-color); }
.alert-danger  { .alert-variant(@alert-danger-bg,  @alert-danger-border,  @alert-danger-link-color); }
